import { Button, Container, Grid } from "@material-ui/core";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch, useSelector } from "react-redux";
import { IsNullOrEmpty } from "../../helpers/utility";
import * as SettingActions from "../../store/setting/setting.actions";
import CoinControl from "../base/coinControl";
import Footer from "../base/Footer";
import NavBar from "../base/NavBar";
import TitleBar from "../base/TitleBar";
import WithdrawDetail from "./WithdrawDetail";
import WithdrawList from "./WithdrawList";

export default function WithdrawContainer() {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("");
  const [coinType, setCoinType] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("DD-MM-YYYY")
  );
  const [endDate, setEndDate] = useState(moment().format("DD-MM-YYYY"));

  const { users, withdraws, selectedUser } = useSelector((state) => {
    return {
      users: state.UserReducer.Users,
      withdraws: state.SettingReducer.withdraws,
      selectedUser: state.UserReducer.selectedUser,
    };
  });

  useEffect(() => {
    if (!IsNullOrEmpty(coinType) && !IsNullOrEmpty(selectedUser)) {
      dispatch(
        SettingActions.loadWithdrawsRequest(
          coinType,
          selectedUser,
          moment().format(),
          moment(startDate, "DD-MM-YYYY"),
          moment(endDate, "DD-MM-YYYY")
        )
      );
    }
  }, [selectedUser, coinType, startDate, endDate]);

  return (
    <div>
      <NavBar
        pageName="Dashboard"
        children={
          <Container maxWidth={false}>
            <TitleBar
              heading="Withdraws"
              secondary={"List of Withdraws"}
              icon={
                <CoinControl
                  onClick={(value) => {
                    setCoinType(value);
                  }}
                />
              }
            />
            <Grid container spacing={2}>
              <Grid item md={12}>
                <DateRangePicker
                  initialSettings={{
                    showDropdowns: true,
                    startDate: startDate,
                    endDate: endDate,
                    maxDate: moment(),
                    locale: {
                      format: "DD-MM-YYYY",
                    },
                  }}
                  onApply={(e, val) => {
                    setStartDate(moment(val.startDate).format("DD-MM-YYYY"));
                    setEndDate(moment(val.endDate).format("DD-MM-YYYY"));
                  }}
                >
                  <Button variant="contained" size="small" color="primary">
                    {moment(startDate, "DD-MM-YYYY").format("LL")} -{" "}
                    {moment(endDate, "DD-MM-YYYY").format("LL")}
                  </Button>
                </DateRangePicker>
                <WithdrawList
                  withdraws={withdraws}
                  onDetailsOpen={(user) => {
                    setMode("details");
                    setSelectedItem(user);
                  }}
                  users={users}
                />
                <WithdrawDetail
                  open={mode === "details"}
                  withdraw={selectedItem}
                  users={users}
                  onUpdate={() => {
                    setMode("list");
                    setSelectedItem({});
                  }}
                  onClose={() => {
                    setMode("list");
                    setSelectedItem({});
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        }
      />
      <Footer />
    </div>
  );
}
