import { collection, getDocs, query } from "firebase/firestore";
import Swal from "sweetalert2";
import { db } from "../../helpers/firebase";
import HttpHelper from "../../helpers/httpHelper";
const headerInfo = {};
class UserAPI {
  static async LoadUsers() {
    let collectionRef = collection(db, "customers");
    const myQuery = query(collectionRef);
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }

  static async LoadUserDetail(id) {
    let url = process.env.REACT_APP_API_URL + "/customers/" + id;
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static AddUser(request) {
    let url = process.env.REACT_APP_API_URL + "/customers";
    return HttpHelper.httpRequest(url, "POST", headerInfo, request);
  }

  static UpdateUser(request) {
    let url = process.env.REACT_APP_API_URL + "/customers/";
    return HttpHelper.httpRequest(url, "PUT", headerInfo, request);
  }

  static DeleteUser(id) {
    let url = process.env.REACT_APP_API_URL + "/customers/" + id;
    return HttpHelper.httpRequest(url, "DELETE", headerInfo);
  }
}

export default UserAPI;
