import { makeStyles } from "@material-ui/core/styles";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React from "react";
import _ from "underscore";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  icon: {
    [theme.breakpoints.down("md")]: {
      position: "relative",
      top: -10,
    },
  },
  chip: {
    marginRight: theme.spacing(1),
    background: theme.palette.info.main,
    color: "white",
  },
}));

export default function ViewUser(props) {
  const classes = useStyles();
  const actionRenderer = () => {
    return {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value) => {
          let user = props.users.filter((item) => {
            return item.id === value;
          });
          return (
            <span>
              <VisibilityIcon
                className={classes.icon}
                color="secondary"
                onClick={() => props.onDetailsOpen(user[0])}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <EditRoundedIcon
                color="primary"
                className={classes.icon}
                onClick={() => {
                  let users = props.users.filter((item) => {
                    return item._id === value;
                  });
                  props.onEdit(users[0]);
                }}
              />
            </span>
          );
        },
      },
    };
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: "Contact No",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "primaryContact",
      label: "Contact Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "primaryEmail",
      label: "Contact Email",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "activeFrom",
      label: "Joined On",
      options: {
        filter: false,
        sort: false,
      },
    },
    actionRenderer(),
  ];

  const data = _.sortBy(props.users, (t) => moment(t.createdOn).unix()).reverse().map(
    (item) => {
      return {
        id: item.id,
        name: item.tenantName,
        website: item.tenantWebsite,
        location: item.country + " / " + item.city,
        phoneNumber: item.tenantPhone,
        primaryContact: item.primaryContactName,
        primaryEmail: item.primaryContactEmail,
        activeFrom: moment(item.createdOn).format("lll"),
      };
    }
  );

  return (
    <div className={classes.container}>
      <MUIDataTable
        title={"User List"}
        data={data}
        columns={columns}
        options={{
          filterType: "checkbox",
          selectableRows: "none",
          rowsPerPageOptions: [10],
        }}
      />
    </div>
  );
}
