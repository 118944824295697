export const UserActionTypes = {
  FETCH_REQUEST: '@@User/FETCH_REQUEST',
  FETCH_SUCCESS: '@@User/FETCH_SUCCESS',

  ADD_REQUEST: '@@User/ADD_REQUEST',
  ADD_SUCCESS: '@@User/ADD_SUCCESS',

  UPDATE_REQUEST: '@@User/UPDATE_REQUEST',
  UPDATE_SUCCESS: '@@User/UPDATE_SUCCESS',

  DELETE_REQUEST: '@@User/DELETE_REQUEST',
  DELETE_SUCCESS: '@@User/DELETE_SUCCESS',

  DETAIL_REQUEST: '@@User/DETAIL_REQUEST',
  DETAIL_SUCCESS: '@@User/DETAIL_SUCCESS',

  FETCH_ERROR: '@@User/FETCH_ERROR',

  SELECT_USER: '@@User/SELECT_USER',
};
