import { Container, Grid } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import _ from "underscore";
import { getCoinPrices } from "../../helpers/coinHelper";
import * as SettingActions from "../../store/setting/setting.actions";
import SettingAPI from "../../store/setting/setting.api";
import Footer from "../base/Footer";
import NavBar from "../base/NavBar";
import TitleBar from "../base/TitleBar";
import WalletInfo from "../users/walletInfo";
import RecentDetail from "./RecentDetail";
import RecentList from "./RecentList";

export default function DashboardContainer() {
  const [mode, setMode] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const { adminSetting } = useSelector((response) => {
    return {
      adminSetting: response.SettingReducer.adminSetting,
    };
  });
  const dispatch = useDispatch();
  const [coinPrices, setCoinPrices] = useState([]);
  const [coinBalance, setCoinBalance] = useState([]);

  useEffect(() => {
    dispatch(SettingActions.loadSettingRequest());
    dispatch(SettingActions.loadAdminRequest());
    dispatch(SettingActions.fetchRecentRequest());
  }, []);

  const { users, recents } = useSelector((state) => {
    return {
      users: state.UserReducer.Users,
      recents: state.SettingReducer.recents,
    };
  });

  useEffect(() => {
    getCoinPrices().then((x) => setCoinPrices(x));
  }, [adminSetting]);

  const handleWithdraw = async () => {
    await Promise.all(
      recents
        .filter(
          (t) =>
            t.status === "Approved" &&
            t.model === "Withdrawal" &&
            !t.parentTransactionHash
        )
        .map(async (s) => {
          return await SettingAPI.SendAdminTransaction("withdraw", s.id).then(
            (x) => {
              dispatch(
                SettingActions.updateWithdrawSuccess({
                  dat: x,
                  id: x.id,
                })
              );
            }
          );
        })
    ).then((s) => {
      Swal.fire({
        title: "Admin Coins",
        text: "Admin Coins Transfered Successfully",
        icon: "success",
      });
    });
  };

  return (
    <div>
      <NavBar
        pageName="Dashboard"
        children={
          <Container maxWidth={false}>
            <TitleBar
              heading="Dashboard"
              secondary={"Crypto Users and Wallets"}
              icon={<HelpOutlineIcon />}
            />
            <Grid container spacing={2}>
              <Grid item md={12}>
                <RecentList
                  withdraws={_.sortBy(recents, (item) =>
                    moment.unix(item.createdOn.seconds)
                  ).reverse()}
                  onDetailsOpen={(user) => {
                    setMode("details");
                    setSelectedItem(user);
                  }}
                  users={users}
                />
                <RecentDetail
                  open={mode === "details"}
                  withdraw={selectedItem}
                  users={users}
                  onUpdate={() => {
                    setMode("list");
                    setSelectedItem({});
                  }}
                  onClose={() => {
                    setMode("list");
                    setSelectedItem({});
                  }}
                />
                {/* <Grid item md={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleWithdraw()}
                  >
                    Send Admin Pending
                  </Button>
                </Grid> */}
              </Grid>

              {adminSetting &&
                adminSetting.wallets &&
                adminSetting.wallets.map((x) => {
                  return (
                    <Grid item md={6}>
                      <WalletInfo
                        key={x.walletId}
                        wallet={x}
                        coinPrices={coinPrices}
                        coinBalance={coinBalance}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Container>
        }
      />
      <Footer />
    </div>
  );
}
