var jwtDecode = require('jwt-decode');
var MODE_TYPE = process.env.REACT_APP_MODE_TYPE;

var xrpImg = process.env.PUBLIC_URL + 'images/XRP.png';
var btcImg = process.env.PUBLIC_URL + 'images/BTC.png';
var bchImg = process.env.PUBLIC_URL + 'images/BCH.png';
var trxImg = process.env.PUBLIC_URL + 'images/TRX.png';
var ltcImg = process.env.PUBLIC_URL + 'images/LTC.png';
var ethImg = process.env.PUBLIC_URL + 'images/ETH.png';
var bnbImg = process.env.PUBLIC_URL + 'images/BNB.png';
var usdtImg = process.env.PUBLIC_URL + 'images/TUSDT.png';
var busdImg = process.env.PUBLIC_URL + 'images/BUSDT.png';

export function getLogo(type) {
    switch (type.toLowerCase()) {
        case 'trx':
            return {
                logo: trxImg,
                name: "Tron",
            };
        case 'btc':
            return {
                logo: btcImg,
                name: "Bit Coin"
            };
        case 'bch':
            return {
                logo: bchImg,
                name: "Bit Coin Cash"
            };
        case 'xrp':
            return {
                logo: xrpImg,
                name: "Ripple"
            };
        case 'ltc':
            return {
                logo: ltcImg,
                name: "Lite Coin"
            };
        case 'eth':
            return {
                logo: ethImg,
                name: "Ethereum"
            };
        case 'bnb':
            return {
                logo: bnbImg,
                name: "Binance"
            };
        case 'usdt':
            return {
                logo: usdtImg,
                name: "Tether"
            };
        case 'busd':
            return {
                logo: busdImg,
                name: "BUSD"
            };
        default:
            return {
                logo: trxImg,
                name: "Tron"
            };
    }
}


export function getTransactionUrl(coin) {
    let explorerUrl = "";
    switch (coin.toLowerCase()) {
        case 'btc':
            explorerUrl = MODE_TYPE === "test" ? "https://www.blockchain.com/btc-testnet/tx/" : "https://www.blockchain.com/btc/tx/";
            break;
        case 'bch':
            explorerUrl = MODE_TYPE === "test" ? "https://www.blockchain.com/bch-testnet/tx/" : "https://www.blockchain.com/bch/tx/";
            break;
        case 'ltc':
            explorerUrl = MODE_TYPE === "test" ? "https://chain.so/tx/LTCTEST/tx/" : "https://chain.so/tx/LTC/";
            break;
        case 'trx':
            explorerUrl = MODE_TYPE === "test" ? "https://shasta.tronscan.org/#/transaction/" : "https://tronscan.org/#/transaction/";
            break;
        case 'eth':
            explorerUrl = MODE_TYPE === "test" ? "https://ropsten.etherscan.io/tx/" : "https://etherscan.io/tx/";
            break;
        case 'bnb':
            explorerUrl = MODE_TYPE === "test" ? "https://testnet.bscscan.com/tx/" : "https://bscscan.com/tx/";
            break;
        case 'xrp':
            explorerUrl = MODE_TYPE === "test" ? "https://testnet.xrpl.org/transactions/" : "https://livenet.xrpl.org/transactions/";
            break;
        default:
            break;
    }

    return explorerUrl;
}

export function getBlockChainUrl(coin) {
    let explorerUrl = "";
    switch (coin.toLowerCase()) {
        case 'btc':
            explorerUrl = MODE_TYPE === "test" ? "https://www.blockchain.com/btc-testnet/address/" : "https://www.blockchain.com/btc/address/";
            break;
        case 'bch':
            explorerUrl = MODE_TYPE === "test" ? "https://www.blockchain.com/bch-testnet/address/" : "https://www.blockchain.com/bch/address/";
            break;
        case 'ltc':
            explorerUrl = MODE_TYPE === "test" ? "https://chain.so/address/LTCTEST/" : "https://chain.so/address/LTC/";
            break;
        case 'trx':
            explorerUrl = MODE_TYPE === "test" ? "https://shasta.tronscan.org/#/address/" : "https://tronscan.org/#/address/";
            break;
        case 'eth':
            explorerUrl = MODE_TYPE === "test" ? "https://ropsten.etherscan.io/address/" : "https://etherscan.io/address/";
            break;
        case 'bnb':
            explorerUrl = MODE_TYPE === "test" ? "https://testnet.bscscan.com/address/" : "https://bscscan.com/address/";
            break;
        case 'xrp':
            explorerUrl = MODE_TYPE === "test" ? "https://testnet.xrpl.org/accounts/" : "https://livenet.xrpl.org/accounts/";
            break;
        default:
            break;
    }

    return explorerUrl;
}

export function getHeaderDetail(headerInfo = {}) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE',
        'Authorization': "Bearer " + sessionStorage.getItem("x-wallet-payment")
    };
    headers = { ...headers, ...headerInfo };

    return headers;
}

export function IsNullOrEmpty(name) {
    return name === "" ||
        name === undefined ||
        name === null;
};

export function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !IsNullOrEmpty(str) && !!pattern.test(str);
}

export function validEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !IsNullOrEmpty(email) && re.test(String(email).toLowerCase());
}

export function validPhone(phone) {
    return !IsNullOrEmpty(phone);
}

export const arrayToObject = (array) =>
    array.reduce((obj, item) => {
        obj[item.name] = item.type === "FileDrop" || item.type === "Chip" ? [] : item.defaultValue;
        return obj;
    }, {});

export const replaceLocalSrcTags = (input, localSrc, src) => {
    return input.replace(localSrc, src);

    // if ( images ) {
    //     for ( let uploadedImage of images ) {
    //         input = input.replace( uploadedImage.localSrc, uploadedImage.file.name );
    //     }

    //     return input;
    // }
    // else {
    //     return '';
    // }
};

export function decodeToken() {
    let token = sessionStorage.getItem('x-wallet-payment');
    try {
        if (token !== null && token !== undefined && token !== "")
            return jwtDecode(token);
        else
            return null;
    }
    catch (e) {
        return null;
    }
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
        return v.toString(16);
    });
}

export function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export function convertToSlug(Text) {
    return Text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
        ;
}