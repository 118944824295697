import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      'sans-serif'
    ].join(','),
    fontWeightBold: 700,
    fontWeightLight: 400,
    fontWeightMedium: 600,
    fontWeightRegular: 500
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize"
        }
      }
    },
    MuiAvatar: {
      root: {
        background: "#FFFFFF",
        border: "1px solid #392467",
        color: "#392467"
      }
    }
  },
  palette: {
    primary: {
      main: '#2B3499',
    },
    secondary: {
      main: '#3085C3',
    },
    success: {
      main: '#edffea',
      dark: "#79d70f"
    },
    info: {
      main: '#00aced',
    },
    error: {
      main: '#c70039',
    },
    divider: '#bfbdbd',
    background: {
      default: '#fff',
    },
  },
});

export default theme;
