import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IsNullOrEmpty } from "../../helpers/utility";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(7),
    right: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 220,
    width: "100%",
    background: "white",
  },
}));

export default function CoinControl(props) {
  const classes = useStyles();
  const [coinType, setCoinType] = useState("BNB");

  const { selectedUser } = useSelector((state) => {
    return {
      selectedUser: state.UserReducer.selectedUser,
    };
  });

  useEffect(() => {
    props.onClick(coinType);
  }, []);

  useEffect(() => {
    if (selectedUser && !IsNullOrEmpty(coinType)) {
      props.onClick(coinType);
    }
  }, [selectedUser]);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        placeholder="Select Coin Type"
        name="coinType"
        value={coinType}
        margin="dense"
        onChange={(e) => {
          props.onClick(e.target.value);
          setCoinType(e.target.value);
        }}
        fullWidth
        labelId="coinType"
        id="coinType"
        displayEmpty
      >
        <MenuItem value={undefined}>Select Coin Type</MenuItem>
        {["TRX", "BTC", "BCH", "LTC", "XRP", "ETH", "BNB"].map((x) => {
          return <MenuItem value={x}>{x}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}
