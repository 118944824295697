import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { IsNullOrEmpty } from '../../helpers/utility';
var logo = require('../../images/splash.png');

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    margin: {
        margin: 0,
    },
    textField: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    formControl: {
        margin: 0,
    },
}));

export default function SignIn(props) {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        email: '',
        password: ''
    });

    const [form, setFormValues] = React.useState({
        Submitted: false
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleLogin = (e) => {
        e.preventDefault();
        setFormValues({ ...values, Submitted: true });
        if (!IsNullOrEmpty(values.email) && !IsNullOrEmpty(values.password)) {
            props.onLogin(values.email, values.password);
        }
    };

    return (
        <Container maxWidth="sm" fullWidth>
            <p className='text-center'>
                <img src={process.env.PUBLIC_URL + "/splash.png"} alt={logo} width={200} className='my-2'/>
            </p>
            <TextField
                name="email"
                fullWidth
                error={form.Submitted && IsNullOrEmpty(values.email)}
                helperText={form.Submitted && IsNullOrEmpty(values.email) ? 'Email Cannot be Empty' : ''}
                value={values.email}
                label="Email"
                margin='dense'
                type="email"
                variant="outlined"
                onChange={handleInputChange}
                className={"my-2"}
            />
            <TextField
                name="password"
                fullWidth
                error={form.Submitted && IsNullOrEmpty(values.password)}
                helperText={form.Submitted && IsNullOrEmpty(values.password) ? 'Password Cannot be Empty' : ''}
                value={values.password}
                label="Password"
                type="password"
                margin='dense'
                variant="outlined"
                onChange={handleInputChange}
                className={"my-2"}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleLogin}
                className={"my-2"}
            >
                Sign In
            </Button>
        </Container>
    );
}