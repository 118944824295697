import React, { useState } from "react";

import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  CalendarToday,
  CalendarViewDay,
  Description,
  Email,
  FileCopy,
  Fingerprint,
  FlipToBackOutlined,
  Map,
  OpenInBrowser,
  OpenInNew,
  Phone,
  ZoomOutMapOutlined,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { useDispatch } from "react-redux";
import toastr from "toastr";
import { getBlockChainUrl, getTransactionUrl } from "../../helpers/utility";
import { updateWithdrawSuccess } from "../../store/setting/setting.actions";
import SettingAPI from "../../store/setting/setting.api";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function WithdrawDetail(props) {
  const dispatch = useDispatch();
  const { withdraw } = props;
  const [loading, setLoading] = useState(false);
  if (!withdraw.type) {
    return <span />;
  }

  const restartWithdraw = async (id) => {
    await SettingAPI.UpdateRecentTransactions(id)
      .then((l) => {
        dispatch(
          updateWithdrawSuccess({
            dat: l,
            id: l.id,
          })
        );
      })
      .finally(() => {
        setLoading(false);
        props.onClose();
      });
  };

  const sendAdminCoins = async (id) => {
    setLoading(true);
    await SettingAPI.SendAdminTransaction("withdraw", id)
      .then((s) => {
        dispatch(
          updateWithdrawSuccess({
            dat: s,
            id: s.id,
          })
        );
      })
      .finally(() => {
        setLoading(false);
        props.onClose();
      });
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => props.onClose()}
        >
          Withdraw Detail
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Fingerprint />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Customer ID"
                    primary={withdraw.cid}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.cid);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountBox />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Customer Name"
                    primary={withdraw.name}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.name);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Email />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Customer Email"
                    primary={withdraw.email}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.email);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Phone />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Customer Mobile"
                    primary={withdraw.mobile}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.mobile);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountBalance />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Transaction Coins"
                    primary={`${
                      withdraw.coins
                    } ${withdraw.tokenSymbol || withdraw.type}`}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.coins);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountBalanceWallet />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Transaction Charge"
                    primary={`${
                      withdraw.transactionCharge
                    } ${withdraw.tokenSymbol || withdraw.type} / ${withdraw.transactionChargeFees} ${withdraw.type}`}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            withdraw.transactionCharge
                          );
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Fingerprint />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Transaction ID"
                    primary={withdraw.id}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.id);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Description />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Reference #"
                    primary={withdraw.txnid}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.txnid);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Map />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="From IP Address"
                    primary={withdraw.domainName}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.domainName);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <ZoomOutMapOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText secondary="Status" primary={withdraw.status} />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(withdraw.status);
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <CalendarToday />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Created On"
                    primary={moment
                      .unix(withdraw.createdOn.seconds)
                      .format("lll")}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            moment
                              .unix(withdraw.createdOn.seconds)
                              .format("lll")
                          );
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <CalendarViewDay />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Last Modified On"
                    primary={moment
                      .unix(withdraw.updatedOn.seconds)
                      .format("lll")}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <FileCopy
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            moment
                              .unix(withdraw.updatedOn.seconds)
                              .format("lll")
                          );
                          toastr.success("Copied to clipboard");
                        }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <OpenInBrowser />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Withdraw Address"
                    primary={withdraw.address}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <a
                        target="_blank"
                        href={
                          getBlockChainUrl(withdraw.type) + withdraw.address
                        }
                      >
                        <OpenInNew color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <OpenInBrowser />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Admin Address"
                    primary={withdraw.toAddress}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <a
                        target="_blank"
                        href={
                          getBlockChainUrl(withdraw.type) + withdraw.toAddress
                        }
                      >
                        <OpenInNew color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            {withdraw.tokenAddress && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <FlipToBackOutlined />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Token Address"
                      primary={withdraw.tokenAddress}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <a
                          target="_blank"
                          href={
                            getBlockChainUrl(withdraw.type) +
                            withdraw.tokenAddress
                          }
                        >
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Fingerprint />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Transaction Hash"
                    primary={withdraw.transactionhash}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <a
                        target="_blank"
                        href={
                          getTransactionUrl(withdraw.type) +
                          withdraw.transactionhash
                        }
                      >
                        <OpenInNew color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Fingerprint />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Transaction Charge Hash"
                    primary={withdraw.parentTransactionHash}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <a
                        target="_blank"
                        href={
                          getTransactionUrl(withdraw.type) +
                          withdraw.parentTransactionHash
                        }
                      >
                        <OpenInNew color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <OpenInBrowser />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary="Webhook Url"
                    primary={withdraw.webhookUrl}
                  />
                  {navigator && navigator.clipboard && (
                    <ListItemSecondaryAction>
                      <a target="_blank" href={withdraw.webhookUrl}>
                        <OpenInNew color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          {!withdraw.transactionhash && !withdraw.parentTransactionHash ? (
            <Button
              disabled={loading}
              variant="contained"
              onClick={() => restartWithdraw(withdraw.id)}
              color="primary"
            >
              Restart Withdraw
            </Button>
          ) : withdraw.transactionhash && !withdraw.parentTransactionHash ? (
            <Button
              disabled={loading}
              variant="contained"
              onClick={() => sendAdminCoins(withdraw.id)}
              color="primary"
            >
              Send Admin Coins
            </Button>
          ) : (
            <></>
          )}
          <Button autoFocus onClick={() => props.onClose()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
