import * as Toastr from "toastr";
import "../../assets/css/toastr.min.css";
import { SettingActionTypes } from "./setting.model";

export const initialState = {
  setting: {},
  adminSetting: {},
  isLoading: false,
  transactions: [],
  deposits: [],
  withdraws: [],
  whitelists: [],
  swaps: [],
  recents: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SettingActionTypes.FETCH_ADMIN_REQUEST:
    case SettingActionTypes.FETCH_SWAPS_REQUEST:
    case SettingActionTypes.FETCH_TRANSACTIONS_REQUEST:
    case SettingActionTypes.UPDATE_SETTING_REQUEST:
    case SettingActionTypes.FETCH_WHITELISTS_REQUEST:
    case SettingActionTypes.FETCH_DEPOSITS_REQUEST:
    case SettingActionTypes.FETCH_WITHDRAWS_REQUEST:
    case SettingActionTypes.FETCH_SETTING_REQUEST:
    case SettingActionTypes.FETCH_RECENTS_REQUEST: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case SettingActionTypes.FETCH_TRANSACTIONS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        transactions: action.payload,
      });
    }

    case SettingActionTypes.FETCH_WHITELISTS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        whitelists: action.payload,
      });
    }

    case SettingActionTypes.FETCH_RECENTS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        recents: action.payload,
      });
    }

    case SettingActionTypes.FETCH_SWAPS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        swaps: action.payload,
      });
    }

    case SettingActionTypes.UPDATE_DEPOSITS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        deposits: state.deposits.map((t) => {
          if (t.id === action.payload.id) {
            t = action.payload.dat;
          }

          return t;
        }),
      });
    }

    case SettingActionTypes.UPDATE_WITHDRAW_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        withdraws: state.withdraws.map((t) => {
          if (t.id === action.payload.id) {
            t = action.payload.dat;
          }

          return t;
        }),
        recents: state.recents.map((t) => {
          if (t.id === action.payload.id && t.model === "Withdrawal") {
            t = action.payload.dat;
          }

          return t;
        }),
      });
    }

    case SettingActionTypes.FETCH_WITHDRAWS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        withdraws: action.payload,
      });
    }

    case SettingActionTypes.FETCH_DEPOSITS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        deposits: action.payload,
      });
    }

    case SettingActionTypes.FETCH_ADMIN_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        adminSetting: action.payload,
      });
    }

    case SettingActionTypes.FETCH_SETTING_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        setting: action.payload,
      });
    }

    case SettingActionTypes.UPDATE_SETTING_SUCCESS: {
      Toastr.success("Settings Updated Successfully");
      return Object.assign({}, state, {
        isLoading: false,
        setting: action.payload,
      });
    }

    case SettingActionTypes.FETCH_ERROR: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }

    default: {
      return state;
    }
  }
};

export { reducer as SettingReducer };

