import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from "redux-persist/lib/storage/session";
import { AuthenticationReducer } from './authentication/auth.reducer';
import { SettingReducer } from './setting/setting.reducers';
import { UserReducer } from './user/user.reducers';

const appReducer = combineReducers({
    SettingReducer,
    AuthenticationReducer,
    UserReducer
});

const persistConfig = {
    key: "bitazure-admin",
    storage: storageSession,
};

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
