import { Container, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IsNullOrEmpty } from "../../helpers/utility";
import * as SettingActions from "../../store/setting/setting.actions";
import CoinControl from "../base/coinControl";
import Footer from "../base/Footer";
import NavBar from "../base/NavBar";
import TitleBar from "../base/TitleBar";
import SwapDetail from "./SwapDetail";
import SwapList from "./SwapList";

export default function SwapContainer() {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  const { users, swaps, selectedUser } = useSelector((state) => {
    return {
      users: state.UserReducer.Users,
      swaps: state.SettingReducer.swaps,
      selectedUser: state.UserReducer.selectedUser,
    };
  });

  return (
    <div>
      <NavBar
        pageName="Dashboard"
        children={
          <Container maxWidth={false}>
            <TitleBar
              heading="Swaps"
              secondary={"List of Swaps"}
              icon={
                <CoinControl
                  onClick={(value) => {
                    if (!IsNullOrEmpty(selectedUser)) {
                      dispatch(
                        SettingActions.loadSwapsRequest(
                          value,
                          selectedUser,
                          moment().format()
                        )
                      );
                    }
                  }}
                />
              }
            />
            <Grid container spacing={2}>
              <Grid item md={12}>
                <SwapList
                  swaps={swaps}
                  onDetailsOpen={(user) => {
                    setMode("details");
                    setSelectedItem(user);
                  }}
                  users={users}
                />
                <SwapDetail
                  open={mode === "details"}
                  swap={selectedItem}
                  users={users}
                  onUpdate={() => {
                    setMode("list");
                    setSelectedItem({});
                  }}
                  onClose={() => {
                    setMode("list");
                    setSelectedItem({});
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        }
      />
      <Footer />
    </div>
  );
}
