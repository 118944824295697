import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoinPrices } from '../../helpers/coinHelper';
import * as UserActions from '../../store/user/user.actions';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import AddUser from './Add.user';
import ViewUser from './View.User';
import ViewUserDetails from './View.UserDetails';

export default function UserContainer() {
    const dispatch = useDispatch();
    const [mode, setMode] = useState("list");
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedId, setSelectedId] = useState("");
    const [coinPrices, setCoinPrices] = useState([]);
    const [coinBalance, setCoinBalance] = useState([]);

    const { isLoading, users } = useSelector((state) => {
        return {
            isLoading: state.UserReducer.isLoading,
            users: state.UserReducer.Users,
        }
    });

    useEffect(() => {
        dispatch(UserActions.loadUsersRequest());
        getCoinPrices().then((x) => setCoinPrices(x))
    }, []);


    return (
        <div>
            <NavBar
                pageName="Customers"
                children={
                    <Container maxWidth={false} >
                        <ViewUser
                            users={users}
                            onEdit={(user) => {
                                dispatch(UserActions.loadUserDetailSuccess(user));
                                setMode("add");
                                setSelectedItem(user);
                            }}
                            onDetailsOpen={(user) => {
                                dispatch(UserActions.loadUserDetailSuccess(user));
                                setMode("details");
                                setSelectedItem(user);
                            }}
                        />

                        <AddUser
                            onSave={(data) => {
                                dispatch(UserActions.addUserRequest(data));
                                setMode("list");
                            }}
                            open={mode === "add"}
                            onClose={() => setMode("list")}
                        />
                        <ViewUserDetails
                            open={mode === "details"}
                            user={selectedItem}
                            coinPrices={coinPrices}
                            coinBalance={coinBalance}
                            onClose={() => {
                                setMode("list");
                                setSelectedItem({});
                            }}
                        />
                    </Container>
                }
            />
            <Fab size="small" style={{ position: "fixed", right: 14, bottom: 90 }} color="secondary" aria-label="add" >
                <AddIcon onClick={() => setMode("add")} />
            </Fab>
            <Footer />
        </div>
    );
}
