import React, { useState } from 'react';

import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { AccountBalance, AccountBox, CalendarToday, CalendarTodayRounded, CalendarViewDay, Email, FileCopy, Fingerprint, Phone, Star, Timelapse } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import toastr from "toastr";
import { getBlockChainUrl } from '../../helpers/utility';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function WhitelistDetail(props) {
    const dispatch = useDispatch();
    const { whitelist, tenants } = props;
    const [loading, setLoading] = useState(false);
    if (!whitelist.coinType) {
        return <span />
    }

    let item = props.whitelist;
    let x = !item.coolingPeriod || item.coolingPeriod == -1 ?
        moment.unix(item.createdOn.seconds).format("lll") :
        moment.unix(item.createdOn.seconds).add(item.coolingPeriod, "minutes").format("lll");

    return (
        <div>
            <Dialog maxWidth="md" fullWidth onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={() => props.onClose()}>
                    Whitelist Detail
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer ID' primary={whitelist.customerId} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(whitelist.customerId);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBox />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Name' primary={whitelist.customerName} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(whitelist.customerName);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Email />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Email' primary={whitelist.customerEmail} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(whitelist.customerEmail);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Phone />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Mobile' primary={whitelist.customerPhone} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(whitelist.customerPhone);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalance />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Min Coins' primary={`${whitelist.minCoins} ${whitelist.coinType}`} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalance />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Max Coins' primary={`${whitelist.maxCoins} ${whitelist.coinType}`} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Address' primary={<a className='text-decoration-none' target='_blank' href={getBlockChainUrl(whitelist.coinType) + whitelist.address}>{whitelist.address}</a>} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(whitelist.address);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Timelapse />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Cooling Period' primary={whitelist.coolingPeriod ?
                                        whitelist.coolingPeriod == -1 ? "Immediate" : whitelist.coolingPeriod + " Minutes" : "Immediate"} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarToday />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Created On' primary={moment.unix(whitelist.createdOn.seconds).format("lll")} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(moment.unix(whitelist.createdOn.seconds).format("lll"));
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarViewDay />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Last Modified On' primary={moment.unix(whitelist.updatedOn.seconds).format("lll")} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(moment.unix(whitelist.updatedOn.seconds).format("lll"));
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Star />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Status'
                                        primary={item.isActive ? moment(x).diff(moment(), "minutes") > 0 ? "Cooling" : "Activated" : "Blocked"} />

                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarTodayRounded />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Activated On' primary={moment(x).format("lll")} />

                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.onClose()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
