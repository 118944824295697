import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IsNullOrEmpty } from '../../helpers/utility';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import SignIn from './LoginPage';
import { loginRequest } from '../../store/authentication/auth.actions';

export default function LoginContainer() {
    const dispatch = useDispatch();
    let token = sessionStorage.getItem('x-wallet-payment')?.toString();
    if (!IsNullOrEmpty(token)) {
        return <Redirect to={'/'} />
    }

    return (
        <div>
            <NavBar
                pageName="Customers"
                children={
                    <Grid container={true} spacing={0} style={{ maxHeight: 'calc(80vh)' }}>
                        <Grid item={true} xs={12} lg={12}>
                            <SignIn
                                onLogin={(email, password) => {
                                    dispatch(loginRequest({ email, password }));
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            />
            <Footer />
        </div>
    );
}

