import React, { useState } from 'react';

import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { AccountBalance, AccountBalanceWallet, CalendarToday, CalendarViewDay, Description, Email, FileCopy, Fingerprint, FlipToBackOutlined, OpenInBrowser, OpenInNew, ZoomOutMapOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import toastr from "toastr";
import { getBlockChainUrl, getTransactionUrl } from '../../helpers/utility';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function TransactionDetail(props) {
    const dispatch = useDispatch();
    const { transaction, tenants } = props;
    const [loading, setLoading] = useState(false);
    if (!transaction.type) {
        return <span />
    }

    return (
        <div>
            <Dialog maxWidth="md" fullWidth onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={() => props.onClose()}>
                    Transaction Detail
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Email />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Email' primary={transaction.email} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(transaction.email);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ZoomOutMapOutlined />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Status' primary={transaction.status} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(transaction.status);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalance />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Coins' primary={`${transaction.coins} ${transaction.tokenSymbol || transaction.type}`} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(transaction.coins);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalanceWallet />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Charge' primary={`${transaction.transactionCharge} ${transaction.tokenSymbol || transaction.type} / ${transaction.transactionChargeFees} ${transaction.type}`} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(transaction.transactionCharge);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction ID' primary={transaction.id} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(transaction.id);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Description />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Reference #' primary={transaction.txnid} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(transaction.txnid);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>


                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarToday />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Created On' primary={moment.unix(transaction.createdOn.seconds).format("lll")} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(moment.unix(transaction.createdOn.seconds).format("lll"));
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarViewDay />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Last Modified On' primary={moment.unix(transaction.updatedOn.seconds).format("lll")} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(moment.unix(transaction.updatedOn.seconds).format("lll"));
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <OpenInBrowser />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Withdraw Address' primary={transaction.address} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getBlockChainUrl(transaction.type) + transaction.address}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <OpenInBrowser />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Admin Address' primary={transaction.toAddress} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getBlockChainUrl(transaction.type) + transaction.toAddress}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        {
                            transaction.tokenAddress &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FlipToBackOutlined />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Token Address' primary={transaction.tokenAddress} />
                                        {
                                            navigator && navigator.clipboard &&
                                            <ListItemSecondaryAction>
                                                <a target='_blank' href={getBlockChainUrl(transaction.type) + transaction.tokenAddress}>
                                                    <OpenInNew color="primary" />
                                                </a>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                </List>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Hash' primary={transaction.transactionhash} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getTransactionUrl(transaction.type) + transaction.transactionhash}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Charge Hash' primary={transaction.parentTransactionHash} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getTransactionUrl(transaction.type) + transaction.parentTransactionHash}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}
