import { UserActionTypes } from "./user.model";

export const initialState = {
  Users: [],
  User: {},
  selectedUser: "",
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.ADD_REQUEST:
    case UserActionTypes.UPDATE_REQUEST:
    case UserActionTypes.DELETE_REQUEST:
    case UserActionTypes.DETAIL_REQUEST:
    case UserActionTypes.FETCH_REQUEST: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case UserActionTypes.FETCH_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        Users: action.payload,
      });
    }

    case UserActionTypes.ADD_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        Users: [...state.Users, action.payload],
      });
    }

    case UserActionTypes.UPDATE_SUCCESS: {
      let selectedIndex = state.Users.findIndex(
        (x) => (x.id = action.payload.id)
      );
      let response = state.Users;
      if (selectedIndex > -1) {
        response[selectedIndex] = action.payload;
      }
      return Object.assign({}, state, {
        isLoading: false,
        Users: response,
      });
    }

    case UserActionTypes.DELETE_SUCCESS: {
      let selectedIndex = state.Users.findIndex((x) => (x.id = action.payload));
      let response = state.Users;
      if (selectedIndex > -1) {
        response.splice(selectedIndex, 1);
      }
      return Object.assign({}, state, {
        isLoading: false,
        Users: response,
      });
    }

    case UserActionTypes.DETAIL_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        User: action.payload,
      });
    }

    case UserActionTypes.SELECT_USER: {
      return Object.assign({}, state, {
        isLoading: false,
        selectedUser: action.payload,
      });
    }

    case UserActionTypes.FETCH_ERROR: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }

    default: {
      return state;
    }
  }
};

export { reducer as UserReducer };

