import {
  collection,
  getDocs,
  limitToLast,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import Swal from "sweetalert2";
import { db } from "../../helpers/firebase";
import HttpHelper from "../../helpers/httpHelper";
import { IsNullOrEmpty } from "../../helpers/utility";
const headerInfo = {};
const limit = 1000;
class SettingAPI {
  static async LoadSetting() {
    let url = process.env.REACT_APP_API_URL + "/setting";
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async UpdateSetting(data) {
    let url = process.env.REACT_APP_API_URL + "/setting";
    return HttpHelper.httpRequest(url, "PUT", headerInfo, data);
  }

  static async SendAdminTransaction(type, id) {
    let url =
      process.env.REACT_APP_API_URL + "/transactions/" + type + "/" + id;
    return HttpHelper.httpRequest(url, "PATCH", headerInfo);
  }

  static async LoadAdminSetting() {
    let url = process.env.REACT_APP_API_URL + "/adminsetting";
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async UpdateRecentTransactions(id) {
    let url = process.env.REACT_APP_API_URL + "/transactions/reinstant/" + id;
    return HttpHelper.httpRequest(url, "PATCH", headerInfo);
  }

  static async LoadRecentTransactions() {
    let depositRef = collection(db, "deposits");
    let requestRef = collection(db, "requests");
    let transactionRef = collection(db, "transactions");
    let trans = [];
    const depQuery = query(depositRef, orderBy("createdOn"), limitToLast(10));

    const requestQuery = query(
      requestRef,
      orderBy("createdOn"),
      limitToLast(10)
    );

    const transactionQuery = query(
      transactionRef,
      orderBy("createdOn"),
      limitToLast(10)
    );

    let deposits = await getDocs(depQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          let dat = doc.data();
          dat.model = "Deposit";
          return dat;
        });
      })
      .catch((ex) => {
        return [];
      });

    trans = [...trans, ...deposits];

    let transactions = await getDocs(transactionQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          let dat = doc.data();
          dat.model = "Transaction";
          return dat;
        });
      })
      .catch((ex) => {
        return [];
      });

    trans = [...trans, ...transactions];

    let requests = await getDocs(requestQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          let dat = doc.data();
          dat.model = "Withdrawal";
          return dat;
        });
      })
      .catch((ex) => {
        return [];
      });

    trans = [...trans, ...requests];

    return trans;
  }

  static async FetchTransactions(req) {
    let { type, uid, startDate, endDate } = req;
    let limit = 1000;
    startDate = new Date(moment(startDate).startOf("day"));
    endDate = new Date(moment(endDate).endOf("day"));
    let collectionRef = collection(db, "transactions");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      where("type", "==", type),
      where("createdOn", ">=", startDate),
      where("createdOn", "<=", endDate),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }

  static async FetchDeposits(req) {
    let { type, uid, startDate, endDate } = req;
    let limit = 1000;
    startDate = new Date(moment(startDate).startOf("day"));
    endDate = new Date(moment(endDate).endOf("day"));
    let collectionRef = collection(db, "deposits");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      where("type", "==", type),
      where("createdOn", ">=", startDate),
      where("createdOn", "<=", endDate),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }

  static async FetchWhitelists(options) {
    const { type, uid, startBy } = options;

    let collectionRef = collection(db, "whitelists");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      where("coinType", "==", type),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }

  static async FetchSwaps(options) {
    const { type, uid, startBy } = options;

    let collectionRef = collection(db, "buyRequests");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      where("coinType", "==", type),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });

    let url = process.env.REACT_APP_API_URL + `/swaps?mode=view`;
    if (!IsNullOrEmpty(type)) {
      url = url + `&type=${type}`;
    }
    if (!IsNullOrEmpty(uid)) {
      url = url + `&id=${uid}`;
    }
    if (!IsNullOrEmpty(startBy)) {
      url = url + `&limit=${limit}`;
      url = url + `&startAt=${startBy}`;
    }
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async FetchWithdraws(req) {
    let { type, uid, startDate, endDate } = req;
    let limit = 1000;
    startDate = new Date(moment(startDate).startOf("day"));
    endDate = new Date(moment(endDate).endOf("day"));

    let collectionRef = collection(db, "requests");
    const myQuery = query(
      collectionRef,
      where("tenantId", "==", uid),
      where("type", "==", type),
      where("createdOn", ">=", startDate),
      where("createdOn", "<=", endDate),
      orderBy("createdOn", "desc"),
      limitToLast(limit)
    );
    return await getDocs(myQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          return [];
        }

        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      })
      .catch((ex) => {
        Swal.fire({
          text: ex,
          title: "Error",
          icon: "error",
        });
      });
  }
}

export default SettingAPI;
