export const SettingActionTypes = {
  FETCH_SETTING_REQUEST: '@@Setting/FETCH_SETTING_REQUEST',
  FETCH_SETTING_SUCCESS: '@@Setting/FETCH_SETTING_SUCCESS',
  FETCH_SETTING_FAILURE: '@@Setting/FETCH_SETTING_FAILURE',

  UPDATE_SETTING_REQUEST: '@@Setting/UPDATE_SETTING_REQUEST',
  UPDATE_SETTING_SUCCESS: '@@Setting/UPDATE_SETTING_SUCCESS',
  UPDATE_SETTING_FAILURE: '@@Setting/UPDATE_SETTING_FAILURE',

  FETCH_ADMIN_REQUEST: '@@Admin/FETCH_ADMIN_REQUEST',
  FETCH_ADMIN_SUCCESS: '@@Admin/FETCH_ADMIN_SUCCESS',
  FETCH_ADMIN_FAILURE: '@@Admin/FETCH_ADMIN_FAILURE',

  FETCH_TRANSACTIONS_REQUEST: '@@transactions/FETCH_TRANSACTIONS_REQUEST',
  FETCH_TRANSACTIONS_SUCCESS: '@@transactions/FETCH_TRANSACTIONS_SUCCESS',
  FETCH_TRANSACTIONS_FAILURE: '@@transactions/FETCH_TRANSACTIONS_FAILURE',

  FETCH_DEPOSITS_REQUEST: '@@deposits/FETCH_DEPOSITS_REQUEST',
  FETCH_DEPOSITS_SUCCESS: '@@deposits/FETCH_DEPOSITS_SUCCESS',
  FETCH_DEPOSITS_FAILURE: '@@deposits/FETCH_DEPOSITS_FAILURE',

  FETCH_WITHDRAWS_REQUEST: '@@withdraws/FETCH_WITHDRAWS_REQUEST',
  FETCH_WITHDRAWS_SUCCESS: '@@withdraws/FETCH_WITHDRAWS_SUCCESS',
  FETCH_WITHDRAWS_FAILURE: '@@withdraws/FETCH_WITHDRAWS_FAILURE',

  FETCH_WHITELISTS_REQUEST: '@@whitelists/FETCH_WHITELISTS_REQUEST',
  FETCH_WHITELISTS_SUCCESS: '@@whitelists/FETCH_WHITELISTS_SUCCESS',
  FETCH_WHITELISTS_FAILURE: '@@whitelists/FETCH_WHITELISTS_FAILURE',

  FETCH_SWAPS_REQUEST: '@@whitelists/FETCH_SWAPS_REQUEST',
  FETCH_SWAPS_SUCCESS: '@@whitelists/FETCH_SWAPS_SUCCESS',
  FETCH_SWAPS_FAILURE: '@@whitelists/FETCH_SWAPS_FAILURE',

  UPDATE_DEPOSITS_SUCCESS: '@@deposits/UPDATE_DEPOSITS_SUCCESS',
  UPDATE_WITHDRAW_SUCCESS: '@@deposits/UPDATE_WITHDRAW_SUCCESS',

  FETCH_ERROR: '@@Setting/FETCH_ERROR',

  FETCH_RECENTS_REQUEST: '@@deposits/FETCH_RECENTS_REQUEST',
  FETCH_RECENTS_SUCCESS: '@@deposits/FETCH_RECENTS_SUCCESS',
  FETCH_RECENTS_FAILURE: '@@deposits/FETCH_RECENTS_FAILURE',
};
