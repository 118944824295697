import { AuthenticationActionTypes } from './auth.model';

export const initialState = {
    isLoading: false,
    token: ""
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case AuthenticationActionTypes.LOGIN_REQUEST: {
            return Object.assign( {}, state, {
                isLoading: false,
            } );
        }

        case AuthenticationActionTypes.LOGIN_SUCCESS: {
            sessionStorage.setItem( "x-wallet-payment", action.payload );
            document.location.href = "/";
            return Object.assign( {}, state, {
                isLoading: false,
                token: action.payload
            } );
        }

        case AuthenticationActionTypes.LOGIN_FAILURE: {
            return Object.assign( {}, state, {
                isLoading: false,
            } );
        }

        case AuthenticationActionTypes.LOGOUT_REQUEST: {
            sessionStorage.removeItem( "x-wallet-payment" );
            return Object.assign( {}, state, {
                isLoading: true,
            } );
        }

        case AuthenticationActionTypes.LOGOUT_SUCCESS: {
            sessionStorage.removeItem( "x-wallet-payment" );
            document.location.href = "/";
            return Object.assign( {}, state, {
                isLoading: false,
            } );
        }

        case AuthenticationActionTypes.LOGOUT_FAILURE: {
            sessionStorage.removeItem( "x-wallet-payment" );
            document.location.href = "/";
            return Object.assign( {}, state, {
                isLoading: false,
            } );
        }

        default: {
            return state;
        }
    }
};

export { reducer as AuthenticationReducer };
