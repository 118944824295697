import {
  Container,
  Grid
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IsNullOrEmpty } from "../../helpers/utility";
import * as SettingActions from "../../store/setting/setting.actions";
import CoinControl from "../base/coinControl";
import Footer from "../base/Footer";
import NavBar from "../base/NavBar";
import TitleBar from "../base/TitleBar";
import WhitelistDetail from "./WhitelistDetail";
import WhitelistList from "./WhitelistList";

export default function WhitelistContainer() {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [coinType, setCoinType] = useState(undefined);

  const { users, whitelists, selectedUser } = useSelector((state) => {
    return {
      users: state.UserReducer.Users,
      whitelists: state.SettingReducer.whitelists,
      selectedUser: state.UserReducer.selectedUser,
    };
  });

  useEffect(() => {
    if (!IsNullOrEmpty(coinType) && !IsNullOrEmpty(selectedUser)) {
      dispatch(
        SettingActions.loadWhitelistsRequest(
          coinType,
          selectedUser,
          moment().format()
        )
      );
    }
  }, [selectedUser]);

  return (
    <div>
      <NavBar
        pageName="Dashboard"
        children={
          <Container maxWidth={false}>
            <TitleBar
              heading="Whitelists"
              secondary={"List of Whitelists"}
              icon={
                <CoinControl
                  onClick={(value) => {
                    if (!IsNullOrEmpty(selectedUser)) {
                      dispatch(
                        SettingActions.loadWhitelistsRequest(
                          value,
                          selectedUser,
                          moment().format()
                        )
                      );
                    }
                  }}
                />
              }
            />
            <Grid container spacing={2}>
              <Grid item md={12}>
                <WhitelistList
                  whitelists={whitelists}
                  onDetailsOpen={(user) => {
                    setMode("details");
                    setSelectedItem(user);
                  }}
                  users={users}
                />
                <WhitelistDetail
                  open={mode === "details"}
                  whitelist={selectedItem}
                  users={users}
                  onUpdate={() => {
                    setMode("list");
                    setSelectedItem({});
                  }}
                  onClose={() => {
                    setMode("list");
                    setSelectedItem({});
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        }
      />
      <Footer />
    </div>
  );
}
